import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function IconLeftSqlEditor(props: SvgIconProps) {
  const { sx, ...others } = props;
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      {...others}
      sx={{
        width: "16px",
        height: "16px",
        fill: "none",
        ...sx,
      }}
    >
      <path
        d="M22 9H2M14 17.5L16.5 15L14 12.5M10 12.5L7.5 15L10 17.5M2 7.8L2 16.2C2 17.8802 2 18.7202 2.32698 19.362C2.6146 19.9265 3.07354 20.3854 3.63803 20.673C4.27976 21 5.11984 21 6.8 21H17.2C18.8802 21 19.7202 21 20.362 20.673C20.9265 20.3854 21.3854 19.9265 21.673 19.362C22 18.7202 22 17.8802 22 16.2V7.8C22 6.11984 22 5.27977 21.673 4.63803C21.3854 4.07354 20.9265 3.6146 20.362 3.32698C19.7202 3 18.8802 3 17.2 3L6.8 3C5.11984 3 4.27976 3 3.63803 3.32698C3.07354 3.6146 2.6146 4.07354 2.32698 4.63803C2 5.27976 2 6.11984 2 7.8Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  );
}

export function IconTopContactUs(props: SvgIconProps) {
  const { sx, ...others } = props;
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      {...others}
      sx={{
        width: "24px",
        height: "24px",
        fill: "none",
        ...sx,
      }}
    >
      <path
        d="M10 15L6.92474 18.1137C6.49579 18.548 6.28131 18.7652 6.09695 18.7805C5.93701 18.7938 5.78042 18.7295 5.67596 18.6076C5.55556 18.4672 5.55556 18.162 5.55556 17.5515V15.9916C5.55556 15.444 5.10707 15.0477 4.5652 14.9683V14.9683C3.25374 14.7762 2.22378 13.7463 2.03168 12.4348C2 12.2186 2 11.9605 2 11.4444V6.8C2 5.11984 2 4.27976 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C4.27976 2 5.11984 2 6.8 2H14.2C15.8802 2 16.7202 2 17.362 2.32698C17.9265 2.6146 18.3854 3.07354 18.673 3.63803C19 4.27976 19 5.11984 19 6.8V11M19 22L16.8236 20.4869C16.5177 20.2742 16.3647 20.1678 16.1982 20.0924C16.0504 20.0255 15.8951 19.9768 15.7356 19.9474C15.5558 19.9143 15.3695 19.9143 14.9969 19.9143H13.2C12.0799 19.9143 11.5198 19.9143 11.092 19.6963C10.7157 19.5046 10.4097 19.1986 10.218 18.8223C10 18.3944 10 17.8344 10 16.7143V14.2C10 13.0799 10 12.5198 10.218 12.092C10.4097 11.7157 10.7157 11.4097 11.092 11.218C11.5198 11 12.0799 11 13.2 11H18.8C19.9201 11 20.4802 11 20.908 11.218C21.2843 11.4097 21.5903 11.7157 21.782 12.092C22 12.5198 22 13.0799 22 14.2V16.9143C22 17.8462 22 18.3121 21.8478 18.6797C21.6448 19.1697 21.2554 19.5591 20.7654 19.762C20.3978 19.9143 19.9319 19.9143 19 19.9143V22Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  );
}

export function IconTopApplyForPoc(props: SvgIconProps) {
  const { sx, ...others } = props;
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      {...others}
      sx={{
        width: "16px",
        height: "16px",
        fill: "none",
        ...sx,
      }}
    >
      <path
        d="M7 8.5H12M7 12H15M7 18V20.3355C7 20.8684 7 21.1348 7.10923 21.2716C7.20422 21.3906 7.34827 21.4599 7.50054 21.4597C7.67563 21.4595 7.88367 21.2931 8.29976 20.9602L10.6852 19.0518C11.1725 18.662 11.4162 18.4671 11.6875 18.3285C11.9282 18.2055 12.1844 18.1156 12.4492 18.0613C12.7477 18 13.0597 18 13.6837 18H16.2C17.8802 18 18.7202 18 19.362 17.673C19.9265 17.3854 20.3854 16.9265 20.673 16.362C21 15.7202 21 14.8802 21 13.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V14C3 14.93 3 15.395 3.10222 15.7765C3.37962 16.8117 4.18827 17.6204 5.22354 17.8978C5.60504 18 6.07003 18 7 18Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  );
}

export function IconTopSupport(props: SvgIconProps) {
  const { sx, ...others } = props;
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      {...others}
      sx={{
        width: "16px",
        height: "16px",
        fill: "none",
        ...sx,
      }}
    >
      <path
        d="M9.09 9C9.3251 8.33167 9.78915 7.76811 10.4 7.40913C11.0108 7.05016 11.7289 6.91894 12.4272 7.03871C13.1255 7.15849 13.7588 7.52152 14.2151 8.06353C14.6713 8.60553 14.9211 9.29152 14.92 10C14.92 12 11.92 13 11.92 13M12 17H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  );
}

export function IconTopOrganization(props: SvgIconProps) {
  const { sx, ...others } = props;
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      {...others}
      sx={{
        width: "24px",
        height: "24px",
        fill: "none",
        ...sx,
      }}
    >
      <path
        d="M13 11H17.8C18.9201 11 19.4802 11 19.908 11.218C20.2843 11.4097 20.5903 11.7157 20.782 12.092C21 12.5198 21 13.0799 21 14.2V21M13 21V6.2C13 5.0799 13 4.51984 12.782 4.09202C12.5903 3.71569 12.2843 3.40973 11.908 3.21799C11.4802 3 10.9201 3 9.8 3H6.2C5.0799 3 4.51984 3 4.09202 3.21799C3.71569 3.40973 3.40973 3.71569 3.21799 4.09202C3 4.51984 3 5.0799 3 6.2V21M22 21H2M6.5 7H9.5M6.5 11H9.5M6.5 15H9.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  );
}

export function IconTopAccountSettings(props: SvgIconProps) {
  const { sx, ...others } = props;
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      {...others}
      sx={{
        width: "24px",
        height: "24px",
        fill: "none",
        ...sx,
      }}
    >
      <path
        d="M5.3163 19.4384C5.92462 18.0052 7.34492 17 9 17H15C16.6551 17 18.0754 18.0052 18.6837 19.4384M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  );
}

export function IconLeftOverview(props: SvgIconProps) {
  const { sx, ...others } = props;
  return (
    <SvgIcon
      viewBox="0 0 18 18"
      {...others}
      sx={{
        width: "16px",
        height: "16px",
        fill: "none",
        ...sx,
      }}
    >
      <path
        d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  );
}

export function IconLeftSqlDiagnosis(props: SvgIconProps) {
  const { sx, ...others } = props;
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      {...others}
      sx={{
        width: "16px",
        height: "16px",
        fill: "none",
        ...sx,
      }}
    >
      <path
        d="M21 21H6.2C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4802 3 18.9201 3 17.8V3M7 10.5V17.5M11.5 5.5V17.5M16 10.5V17.5M20.5 5.5V17.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  );
}

export function IconLeftMonitoring(props: SvgIconProps) {
  const { sx, ...others } = props;
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      {...others}
      sx={{
        width: "16px",
        height: "16px",
        fill: "none",
        ...sx,
      }}
    >
      <path
        d="M9.49799 2.26953V6.40007C9.49799 6.96012 9.49799 7.24015 9.38899 7.45406C9.29312 7.64222 9.14014 7.7952 8.95198 7.89108C8.73807 8.00007 8.45804 8.00007 7.89799 8.00007H3.76745M12.498 22H8.29799C6.61783 22 5.77775 22 5.13602 21.673C4.57153 21.3854 4.11259 20.9265 3.82497 20.362C3.49799 19.7202 3.49799 18.8802 3.49799 17.2V8L9.49799 2H14.698C16.3781 2 17.2182 2 17.86 2.32698C18.4244 2.6146 18.8834 3.07354 19.171 3.63803C19.498 4.27976 19.498 5.11984 19.498 6.8V10M20.8601 17.2L17.8601 14.2M17.8601 14.2L14.8601 17.2M17.8601 14.2L17.8601 17.2V22"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  );
}

export function IconLeftDataMigration(props: SvgIconProps) {
  const { sx, ...others } = props;
  return (
    <SvgIcon
      viewBox="0 0 20 18"
      {...others}
      sx={{
        width: "16px",
        height: "16px",
        fill: "none",
        ...sx,
      }}
    >
      <path
        d="M3.33332 12.5352C2.32834 11.8625 1.66666 10.7168 1.66666 9.41667C1.66666 7.46369 3.15958 5.85941 5.06644 5.68281C5.4565 3.31011 7.51686 1.5 9.99999 1.5C12.4831 1.5 14.5435 3.31011 14.9335 5.68281C16.8404 5.85941 18.3333 7.46369 18.3333 9.41667C18.3333 10.7168 17.6716 11.8625 16.6667 12.5352M6.66666 12.3333L9.99999 9M9.99999 9L13.3333 12.3333M9.99999 9V16.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  );
}

export function IconLeftBackup(props: SvgIconProps) {
  const { sx, ...others } = props;
  return (
    <SvgIcon
      viewBox="0 0 16 20"
      {...others}
      sx={{
        width: "16px",
        height: "16px",
        fill: "none",
        ...sx,
      }}
    >
      <path
        d="M12.1667 4.27118C13.9344 5.55912 15.0833 7.6454 15.0833 10C15.0833 13.912 11.912 17.0833 7.99999 17.0833H7.58332M3.83332 15.7288C2.06554 14.4409 0.916656 12.3546 0.916656 10C0.916656 6.08799 4.08797 2.91668 7.99999 2.91668H8.41666M8.83332 18.6667L7.16666 17L8.83332 15.3333M7.16666 4.66668L8.83332 3.00001L7.16666 1.33334"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  );
}

export function IconLeftDataService(props: SvgIconProps) {
  const { sx, ...others } = props;
  return (
    <SvgIcon
      viewBox="0 -2 24 24"
      {...others}
      sx={{
        width: "16px",
        height: "16px",
        fill: "none",
        ...sx,
      }}
    >
      <path
        d="M14 20C14 21.1046 13.1046 22 12 22C10.8954 22 10 21.1046 10 20M14 20C14 18.8954 13.1046 18 12 18M14 20H21M10 20C10 18.8954 10.8954 18 12 18M10 20H3M12 18V14M21 5C21 6.65685 16.9706 8 12 8C7.02944 8 3 6.65685 3 5M21 5C21 3.34315 16.9706 2 12 2C7.02944 2 3 3.34315 3 5M21 5V11C21 12.66 17 14 12 14M3 5V11C3 12.66 7 14 12 14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  );
}

export function IconCreateDataApp(props: SvgIconProps) {
  const { sx, ...others } = props;
  return (
    <SvgIcon
      viewBox="0 -2 24 24"
      {...others}
      sx={{
        width: "16px",
        height: "16px",
        fill: "none",
        ...sx,
      }}
    >
      <path
        d="M20.6397 7.27783L12.4797 12.0001M12.4797 12.0001L12.4798 21.5001M12.4797 12.0001L9.83976 10.4723L8.51977 9.7084M21.1198 12.0001V7.94153C21.1198 7.59889 21.1198 7.42757 21.0713 7.27477C21.0284 7.13959 20.9583 7.01551 20.8657 6.91082C20.761 6.79248 20.6173 6.70928 20.3297 6.54288L13.2257 2.43177C12.9535 2.27421 12.8173 2.19543 12.6732 2.16454C12.5456 2.13721 12.414 2.13721 12.2864 2.16454C12.1422 2.19543 12.0061 2.27421 11.7338 2.43177L9.92124 3.33908M15.0017 20.5406L13.2257 21.5684C12.9535 21.726 12.8173 21.8047 12.6732 21.8356C12.5456 21.863 12.414 21.863 12.2864 21.8356C12.1422 21.8047 12.0061 21.726 11.7338 21.5684L5.75977 17.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>

      <path
        d="M19.1998 21V15M16.3198 18H22.0798"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>

      <path
        d="M3.07206 3.8C3.07206 3.51997 3.07206 3.37996 3.12438 3.273C3.1704 3.17892 3.24383 3.10243 3.33415 3.0545C3.43682 3 3.57124 3 3.84006 3H6.14406C6.41289 3 6.5473 3 6.64998 3.0545C6.74029 3.10243 6.81372 3.17892 6.85974 3.273C6.91206 3.37996 6.91206 3.51997 6.91206 3.8V6.2C6.91206 6.48003 6.91206 6.62004 6.85974 6.727C6.81372 6.82108 6.74029 6.89757 6.64998 6.9455C6.5473 7 6.41289 7 6.14406 7H3.84006C3.57124 7 3.43682 7 3.33415 6.9455C3.24383 6.89757 3.1704 6.82108 3.12438 6.727C3.07206 6.62004 3.07206 6.48003 3.07206 6.2V3.8Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>

      <path
        d="M2 11.6134C2 11.3334 2 11.1934 2.05232 11.0865C2.09834 10.9924 2.17177 10.9159 2.26208 10.8679C2.36476 10.8134 2.49917 10.8134 2.768 10.8134H5.072C5.34082 10.8134 5.47524 10.8134 5.57792 10.8679C5.66823 10.9159 5.74166 10.9924 5.78768 11.0865C5.84 11.1934 5.84 11.3334 5.84 11.6134V14.0134C5.84 14.2935 5.84 14.4335 5.78768 14.5404C5.74166 14.6345 5.66823 14.711 5.57792 14.759C5.47524 14.8134 5.34082 14.8134 5.072 14.8134H2.768C2.49917 14.8134 2.36476 14.8134 2.26208 14.759C2.17177 14.711 2.09834 14.6345 2.05232 14.5404C2 14.4335 2 14.2935 2 14.0134V11.6134Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>

    </SvgIcon>
  );
}
